<template>
  <modal sync="show" body-classes="pt-0 pb-0" @close="closeLayer" size="lg">
    <h6 slot="header" class="modal-title">API 인증정보</h6>
    <template>
      <form method="post" action="#" role="form" class="needs-validation" name="loginForm">
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">CLIENT_ID</label>
          <div class="col-md-9">
            <base-input v-model="model.subClientId"
                        name="subClientId"
                        :readonly="true">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">CLIENT_SECRET</label>
          <div class="col-md-9">
            <base-input v-model="model.subClientSecret"
                        name="subClientSecret"
                        :readonly="true">
            </base-input>
          </div>
        </div>
      </form>
    </template>
    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
    </template>
  </modal>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import commJsUtilMixin from '@/shared/mixins/commJsUtil'
import commValidatorMixin from '@/shared/mixins/commValidatorMixin'
import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const'
import swal from "sweetalert2";

export default {
  inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
  name: 'SubOauthLayer',
  mixins: [ commJsUtilMixin, commValidatorMixin ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    kkoSeqno: Number
  },
  data() {
    return {
      model: {
        kkoSeqno  : '',
        subClientId : null,
        subClientSecret : null,
      }
    }
  },
  created() {
    this.model.kkoSeqno = this.kkoSeqno
    this.retrieveResellInfo();
  },
  methods: {
    retrieveResellInfo(){
      this.$axios.get(`${USER_API_PREFIX}/resell/${this.model.kkoSeqno}`, {
      }).then((res) => {
        this.model.subClientId      = res.data.result.resellInfo.subClientId;
        this.model.subClientSecret  = res.data.result.resellInfo.subClientSecret;
      }).catch((error) => {
        console.log(error)
      })
    },
    /**
     * 레이어 닫기
     */
    closeLayer() {
      this.$emit('close');
    },

    /**
     * 알림 메시지 생성
     * @param type
     */
    notify(type = 'default', message) {
      let notiMsg = message ? message : ''
      this.$notify({
        message: notiMsg,
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      })
    }
  }
};
</script>

<style>
</style>
