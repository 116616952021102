<template>
  <modal :show.sync="show" body-classes="pt-0 pb-0" @close="closeLayer" size="lg">
    <h6 slot="header" class="modal-title">고객 {{ isModify ? '조회' : '등록' }}</h6>
    <template>
      <form method="post" action="#" role="form" class="needs-validation" name="loginForm">
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">고객명 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.subCompanyName"
                        name="subCompanyName"
                        :error="_getError('subCompanyName')"
                        placeholder="고객명 입력"
                        v-validate="'required'"
                        :maxlength="30"
                        :readonly="isModify">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">채널ID <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.subChannelId"
                        name="managerPhoneNo"
                        :error="_getError('channelId')"
                        placeholder="채널ID"
                        v-validate="'required'"
                        :readonly="model.subChannelId"
                        v-if="model.subChannelId">
            </base-input>
            <base-button type="primary" size="sm" outline v-else @click="openSubChannelLayer()">
              채널ID 선택
            </base-button>
          </div>
        </div>
        <div class="form-group row mb-0" style="margin-top: 0.5rem;">
          <label class="col-md-3 col-form-label form-control-label">제공서비스 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input>
              <base-radio v-model="model.offerCd" class="mt-1 mb-2" name="AGENT" :inline="true" :disabled="isModify">AGENT</base-radio>
              <base-radio v-model="model.offerCd" class="mt-1 mb-2" name="API" :inline="true" :disabled="isModify">API</base-radio>
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">사업자번호 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.bizNo"
                        name="bizNo"
                        :error="_getError('bizNo')"
                        placeholder="사업자번호 입력"
                        v-validate="'required|bizNo'"
                        :maxlength="30"
                        :readonly="isModify"
                        @keyup="bizNoMask">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">담당자명 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.managerName"
                        name="managerName"
                        :error="_getError('managerName')"
                        placeholder="담당자명 입력"
                        v-validate="'required'"
                        :maxlength="10"
                        :readonly="isModify">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">담당자전화번호 <span class="text-danger">*</span></label>
          <div class="col-md-9">
            <base-input v-model="model.managerPhoneNo"
                        name="managerPhoneNo"
                        :error="_getError('managerPhoneNo')"
                        placeholder="담당자전화번호 입력"
                        v-validate="'required|mobileNo'"
                        @keyup="phoneNoMask"
                        :maxlength="13"
                        :readonly="isModify">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">일 평균 발송량</label>
          <div class="col-md-9">
            <base-input v-model="model.daySendCntAvg"
                        name="daySendCntAvg"
                        placeholder="일 평균 발송량 입력"
                        :maxlength="10"
                        @keyup="onlyNumber('daySendCntAvg')"
                        :readonly="isModify">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">일 최대 발송량</label>
          <div class="col-md-9">
            <base-input v-model="model.daySendCntMax"
                        name="daySendCntMax"
                        placeholder="일 최대 발송량 입력"
                        :maxlength="10"
                        @keyup="onlyNumber('daySendCntMax')"
                        :readonly="isModify">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">월 평균 발송량</label>
          <div class="col-md-9">
            <base-input v-model="model.monthSendCntAvg"
                        name="monthSendCntAvg"
                        placeholder="월 평균 발송량 입력"
                        :maxlength="10"
                        @keyup="onlyNumber('monthSendCntAvg')"
                        :readonly="isModify">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">월 최대 발송량</label>
          <div class="col-md-9 ">
            <base-input v-model="model.monthSendCntMax"
                        name="monthSendCntMax"
                        placeholder="월 최대 발송량 입력"
                        :maxlength="10"
                        @keyup="onlyNumber('monthSendCntMax')"
                        :readonly="isModify">
            </base-input>
          </div>
        </div>
        <div class="form-group row mb-0">
          <label class="col-md-3 col-form-label form-control-label">오픈희망일 <span class="text-danger">*</span></label>
            <div class="col-md-9 form-inline">
              <base-input v-model="model.openDate"
                          name="openDate"
                          :error="_getError('openDate')"
                          :readonly="isModify">
                <flat-picker slot-scope="{focus, blur}"
                             @on-open="focus"
                             @on-close="blur"
                             :config="{allowInput: true}"
                             class="form-control datepicker"
                             v-model="model.openDate"
                             :readonly="isModify">
                </flat-picker>
              </base-input>
            </div>
        </div>
      </form>
    </template>

    <template slot="footer">
      <base-button type="link" @click="closeLayer">닫기</base-button>
      <base-button type="primary" class="ml-auto" @click="validateBeforeSubmit()" v-if="!isModify">등록</base-button>
    </template>

    <!-- 채널 선택 레이어 -->
    <sub-channel-layer
      v-if="showSubChannelLayer"
      @register="registerSubChannel"
      @close="closeSubChannelLayer">
    </sub-channel-layer>
  </modal>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import commJsUtilMixin from '@/shared/mixins/commJsUtil'
import commValidatorMixin from '@/shared/mixins/commValidatorMixin'
import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const'
import flatPicker from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import swal from "sweetalert2";
import SubChannelLayer from './SubChannelLayer'

export default {
  inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
  name: 'ResellRegisterLayer',
  mixins: [ commJsUtilMixin, commValidatorMixin ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    flatPicker,
    SubChannelLayer
  },
  props: {
    isModify: Boolean,
    kkoSeqno: Number
  },
  data() {
    return {
      show: true,
      showSubChannelLayer : false,
      yearList    : [],
      monthList   : [],
      dayList     : [],
      model: {
        kkoSeqno  : '',
        subClientId : null,
        subCompanyName : null,
        subChannelId : null,
        offerCd : 'AGENT',
        daySendCntAvg : 0,
        daySendCntMax : 0,
        monthSendCntAvg : 0,
        monthSendCntMax : 0,
        bizNo : null,
        managerName : null,
        managerPhoneNo : null,
        openDate : this.$moment(new Date()).format('YYYY-MM-DD'),
      }
    }
  },
  created() {
    if(this.isModify){
      this.model.kkoSeqno = this.kkoSeqno
      this.retrieveResellInfo();
    }
  },
  methods: {

    /**
     * 키 이벤트로 오직 숫자만 입력되도록 처리
     */
    onlyNumber(type) {
      if('daySendCntAvg' === type) {
        this.model.daySendCntAvg = this._onlyNumber(this.model.daySendCntAvg);
      } else if('daySendCntMax' === type){
        this.model.daySendCntMax = this._onlyNumber(this.model.daySendCntMax);
      } else if('monthSendCntAvg' === type){
        this.model.monthSendCntAvg = this._onlyNumber(this.model.monthSendCntAvg);
      } else if('monthSendCntMax' === type){
        this.model.monthSendCntMax = this._onlyNumber(this.model.monthSendCntMax);
      }
    },


    /**
     * 유효성체크
     */
    validateBeforeSubmit(){
      let chk = true;
      if(this.checkNull(this.model.subCompanyName)) {
        this.alertMsg('고객명을 입력해 주세요.');
        chk = false;
        return false;
      }
      if(this.checkNull(this.model.subChannelId)) {
        this.alertMsg('채널ID 선택해 주세요.')
        chk = false;
        return false;
      }
      if(this.checkNull(this.model.bizNo)) {
        this.alertMsg('사업자번호를 입력해 주세요.')
        chk = false;
        return false;
      }
      if(this.checkNull(this.model.managerName)) {
        this.alertMsg('담당자명을 입력해 주세요.')
        chk = false;
        return false;
      }
      if(this.checkNull(this.model.managerPhoneNo)) {
        this.alertMsg('담당자 전화번호를 입력해 주세요.')
        chk = false;
        return false;
      }

      if(chk){
        this._validateBeforeSubmit(this.register);
      }
    },

    /**
     * 등록
     */
    register() {
      this.$axios.post(`${USER_API_PREFIX}/resell/create`, {
        subClientId     : this.model.subClientId,
        subCompanyName  : this.model.subCompanyName,
        subChannelId    : this.model.subChannelId,
        offerCd         : this.model.offerCd,
        daySendCntAvg   : this.model.daySendCntAvg,
        daySendCntMax   : this.model.daySendCntMax,
        monthSendCntAvg : this.model.monthSendCntAvg,
        monthSendCntMax : this.model.monthSendCntMax,
        openDate        : this.model.openDate,
        bizNo           : this.model.bizNo,
        managerName     : this.model.managerName,
        managerPhoneNo  : this.model.managerPhoneNo,
      }).then(res => {
        if (res.data.result.code === RESULT_CODE.SUCCESS) {
          this.$emit('close');
          this.notify('default', res.data.result.message);
        } else {
          this.notify('warning', res.data.result.message);
        }
      }).catch(error => {
        console.log(error.response)
      })
    },


    retrieveResellInfo(){
      this.$axios.get(`${USER_API_PREFIX}/resell/${this.model.kkoSeqno}`, {
      }).then((res) => {
        this.model.subClientId     = res.data.result.resellInfo.subClientId;
        this.model.subCompanyName  = res.data.result.resellInfo.subCompanyName;
        this.model.subChannelId    = res.data.result.resellInfo.subChannelId;
        this.model.offerCd         = res.data.result.resellInfo.offerCd;
        this.model.daySendCntAvg   = res.data.result.resellInfo.daySendCntAvg;
        this.model.daySendCntMax   = res.data.result.resellInfo.daySendCntMax;
        this.model.monthSendCntAvg = res.data.result.resellInfo.monthSendCntAvg;
        this.model.monthSendCntMax = res.data.result.resellInfo.monthSendCntMax;
        this.model.openDate        = res.data.result.resellInfo.openDate;
        this.model.bizNo           = res.data.result.resellInfo.bizNo;
        this.model.managerName     = res.data.result.resellInfo.managerName;
        this.model.managerPhoneNo  = res.data.result.resellInfo.managerPhoneNo;
      }).catch((error) => {
        console.log(error)
      })
    },

    /**
     * 레이어 닫기
     */
    closeLayer() {
      this.$emit('close');
    },

    /**
     * 채널 레이어 닫기
     */
    openSubChannelLayer() {
      this.showSubChannelLayer = true
    },

    /**
     * 채널 레이어 닫기
     */
    closeSubChannelLayer() {
      this.showSubChannelLayer = false
    },

    /**
     * 채널 선택
     */
    registerSubChannel(channelId){
      this.model.subChannelId = channelId;
    },
    /**
     * null check
     */
    checkNull(param) {
      if (param === undefined || param === null || '' == param) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
     */
    phoneNoMask() {
      this.model.managerPhoneNo = this._autoHyphenPhoneNumber(this.model.managerPhoneNo);
    },
    /**
     * 키 이벤트로 사업자등록번호 형식으로 문자열 변경
     */
    bizNoMask() {
      this.model.bizNo = this._autoHyphenBizNumber(this.model.bizNo);
    },
    /**
     * 알럿메시지 출력
     */
    alertMsg(msg , title){
      let titleMsg = title ? title : '경고';
      let type = title ? 'info' : 'warning';
      swal({
        title: titleMsg,
        text: msg,
        buttonsStyling: false,
        confirmButtonClass: 'btn btn-'+type,
        type: type,
        width: 400
      });
    },
    /**
     * 알림 메시지 생성
     * @param type
     */
    notify(type = 'default', message) {
      let notiMsg = message ? message : ''
      this.$notify({
        message: notiMsg,
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      })
    }
  }
};
</script>

<style>
</style>
