<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">고객사 관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">고객 목록</h3>
            <div class="float-right">
              <base-button type="danger" block @click="showRegisterLayer">고객사 등록</base-button>
            </div>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap p-0">
              <div class="col-2">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
              <div class="col-6 form-group row mb-0">
                <div class="col-md-4 col-4">
                  <el-select v-model="search.status"
                             filterable
                             placeholder="상태"
                             @change="retrieveResellList(1)">
                    <el-option v-for="option in statusOptions"
                               :key="option.value"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-md-8 col-8 pl-0">
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="고객명" aria-label="고객명 입력" aria-describedby="button-addon2"
                           v-model="search.subCompanyName"
                           @keydown.enter="retrieveResellList(1)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="retrieveResellList(1)">검색</button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>

            <el-table :data="resellList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'결과가 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column label="번호" min-width="7%">
                <template slot-scope="scope">
                  {{ pagination.total - (pagination.currentPage-1)*pagination.perPage-scope.$index }}
                </template>
              </el-table-column>
              <el-table-column prop="subCompanyName" label="고객명" min-width="15%"></el-table-column>
              <el-table-column prop="subClientId" label="고객ID" min-width="25%">
                <template slot-scope="scope">
                  <div class="align-items-center">
                    <a href="#" @click="showInfoLayer(scope.row.kkoSeqno)"><u>{{ scope.row.subClientId }}</u></a>
                  </div>
              </template>
              </el-table-column>
              <el-table-column prop="managerName" label="고객담당자명" min-width="15%"></el-table-column>
              <el-table-column prop="status" label="상태" min-width="8%">
                <template slot-scope="scope">{{ scope.row.status | statusFilter }}</template>
              </el-table-column>
              <el-table-column prop="offerCd" label="제공서비스" min-width="20%">
                <template v-slot="{row}">
                  <div class="align-items-center">
                    <a v-if="row.status == 'O' && row.offerCd == 'API'" href="#" @click="showSubOauthLayer(row.kkoSeqno)"><u>{{ row.offerCd }}</u></a>
                    <a v-else>{{ row.offerCd }}</a>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="regDate" label="등록일시" min-width="15%">
                <template slot-scope="scope">{{ scope.row.regDate | convertDatetimeFormat }}</template>
              </el-table-column>

            </el-table>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="retrieveResellList">
            </base-pagination>
          </div>

          <!-- 고객사 등록/수정 레이어 -->
          <resell-register-layer
            v-if="showLayer"
            :is-modify="isModify"
            :kko-seqno="kkoSeqno"
            @close="closeRegisterLayer">
          </resell-register-layer>

          <!-- 고객사 등록/수정 레이어 -->
          <sub-oauth-layer
            v-if="showOauthLayer"
            :kko-seqno="kkoSeqno"
            @close="closeSubOauthLayer">
          </sub-oauth-layer>

        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn, Select, Option } from 'element-ui'
  import commJsUtilMixin from '@/shared/mixins/commJsUtil'
  import { default as commFlatpickrMixin } from '@/shared/mixins/commFlatpickrMixin'
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX , RESULT_CODE} from '@/shared/util/const'
  import swal from "sweetalert2";
  import ResellRegisterLayer from './ResellRegisterLayer'
  import SubOauthLayer from './SubOauthLayer'

  export default {
    name: 'ResellList',
    mixins: [ commFlatpickrMixin, commJsUtilMixin ,commPaginationMixin],
    components: {
      [Select.name] : Select,
      [Option.name] : Option,
      [Table.name]  : Table,
      [TableColumn.name]: TableColumn,
      ResellRegisterLayer,
      SubOauthLayer
    },
    computed: {

    },
    filters: {
      statusFilter: value => {
        let name = '';
        switch (value) {
          case 'N':
            name = '중지';
            break;
          case 'O':
            name = '사용';
            break;
        }
        return name;
      }
    },
    created() {
      this.retrieveResellList();
    },
    data() {
      return {
        resellList : [] ,
        isModify : false,
        showLayer : false,
        showOauthLayer : false,
        kkoSeqno  : '',
        search  : {
          subCompanyName : '',
          status : ''
        },
        statusOptions:[
          {
            label:'=상태=',
            value:''
          },
          {
            label: '중지',
            value: 'N'
          },
          {
            label: '사용',
            value: 'O'
          },

        ],
      }
    },
    methods: {
      /**
       * 발신프로필 목록조회
       */
      retrieveResellList(page) {
        let pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/resell/list`, {
          params : {
            subCompanyName : this.search.subCompanyName,
            status         : this.search.status,
            page           : pageNo,
            rows           : this.pagination.perPage
          }
        }).then(res => {
          this.resellList            = res.data.result.resellList;    // 목록정보 세팅
          this.pagination.currentPage = pageNo;                         // 페이지 번호 세팅
          this.pagination.total       = res.data.result.total;          // 전체페이지수 세팅
        });
      },
      /**
       * 등록 레이어 생성
       */
      showRegisterLayer() {
        this.showLayer = true
        this.isModify  = false;
        this.kkoSeqno = ''
      },
      /**
       * 조회레이어 생성
       * @param kkoSeqno
       */
      showInfoLayer(kkoSeqno) {
        this.showLayer = true
        this.isModify  = true;
        this.kkoSeqno = kkoSeqno
      },

      /**
       * 시크릿 조회 레이어 생성
       * @param kkoSeqno
       */
      showSubOauthLayer(kkoSeqno) {
        this.showOauthLayer = true
        this.kkoSeqno = kkoSeqno
      },
      /**
       * 등록/조회 레이어 닫기
       */
      closeRegisterLayer() {
        this.showLayer = false
        this.kkoSeqno = ''
        if(!this.isModify){
          this.retrieveResellList(1);
        }
      },
      /**
       * 시크릿 조회 레이어 닫기
       */
      closeSubOauthLayer() {
        this.showOauthLayer = false
        this.kkoSeqno = ''
      },
      /**
       * 알럿메시지 출력
       */
      alertMsg(msg , title){
        let titleMsg = title ? title : '경고';
        let type = title ? 'info' : 'warning';
        swal({
          title: titleMsg,
          text: msg,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-'+type,
          type: type,
          width: 400
        });
      },
    }
  }
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
